import { combineReducers } from "redux"

// Layout
import Layout from "./layout/reducer";

// Calendar
import Calendar from "./calendar/reducer";

//Chat
import Chat from "./chat/reducer";

//Invoices
import Invoices from "./invoices/reducer";

//Contact
import contacts from "./contacts/reducer";

//login
import login from "./auth/login/reducer";

//register
import register from "./auth/register/reducer";

// User Profile 
import profile from "./auth/profile/reducer";

// Forget Password
import forgetPassword from "./auth/forgetpwd/reducer";

//state
import states from "./states/reducer";

//customer
import customers from "./customers/reducer";

//product
import products from "./products/reducer";

//company
import companies from "./companies/reducer";

//unit
import units from "./units/reducer";

//tax
import taxes from "./taxes/reducer";

//countries
import countries from "./countries/reducer";

//currencies
import currencies from "./currencies/reducer";

//currencies
import permissions from "./permissions/reducer";

//currencies
import exp_items from "./exp_items/reducer";

//Files
import files from "./files/reducer";

//Payment methods
import payment_methods from "./payment_methods/reducer";

//Bank or Cash
import bankorcash from "./bankorcash/reducer";

//TDS Head
import tdshead from "./tdshead/reducer";

import hsncode from "./hsncode/reducer";

import saccode from "./saccode/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Calendar,
  Chat,
  Invoices,
  contacts,
  login,
  register,
  profile,
  forgetPassword,
  states,
  customers,
  products,
  companies,
  units,
  taxes,
  countries,
  currencies,
  permissions,
  exp_items,
  files,
  payment_methods,
  bankorcash,
  tdshead,
  hsncode,
  saccode,
})

export default rootReducer
