import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Modal } from "reactstrap";

interface Params extends RouteComponentProps {
  isOpen: boolean;
  toggle: () => void;
  ewaybill: string;
  created: string;
  vaildupto: string;
  invoice_no: string;
  ewayPdfUrl: string;
}

type State = {
  modaleway: boolean;
  isSending: boolean;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  ewaybill: string;
  created: string;
  vaildupto: string;
  invoice_no: string;
  ewayPdfUrl: string;
};

class EwayBillModal extends Component<Params, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      modaleway: false,
      isSending: false,
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      ewaybill: "",
      created: "",
      vaildupto: "",
      invoice_no: "",
      ewayPdfUrl: "",
    };
  }
  isValidHttpUrl = (string: string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };
  componentWillReceiveProps(props: Params) {
    this.setState({
      modaleway: props.isOpen,
      ewaybill: props.ewaybill,
      created: props.created,
      vaildupto: props.vaildupto,
      invoice_no: props.invoice_no,
      ewayPdfUrl: props.ewayPdfUrl,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modaleway}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="modal-md"
        >
          <div className="modal-content">
            <div className="modal-body">
            <h5 className="text-success">
                Success
            </h5>
              <table className="table custom-table">
                <tbody>
                  <tr>
                    <td className="w-50">Eway Bill No</td>
                    <td className="w-50">: {this.state.ewaybill}</td>
                  </tr>
                  <tr>
                    <td className="w-50">Date/Created Time</td>
                    <td className="w-50">: {this.state.created}</td>
                  </tr>
                  <tr>
                    <td className="w-50">Valiity</td>
                    <td className="w-50">: {this.state.vaildupto}</td>
                  </tr>
                  <tr>
                    <td className="w-50">Invoice no</td>
                    <td className="w-50">: {this.state.invoice_no}</td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex custom-modal-footer">
                <div className="text-center w-100 custom-text-center">
                  <Button
                    type="button"
                    className="btn-sm bmt-btn-close inv-info"
                    onClick={() => this.props.toggle()}
                  >
                    Close
                  </Button>
                  <a
                    href={
                      this.isValidHttpUrl(this.state.ewayPdfUrl)
                        ? this.state.ewayPdfUrl
                        : "https://" + this.state.ewayPdfUrl
                    }
                    target="_blank"
                  >
                    <Button type="button" className="bmt-btn-submit inv-info">
                      Dowload
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(EwayBillModal);
