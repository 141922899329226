import { all, fork } from "redux-saga/effects"

//Layout
import LayoutSaga from "./layout/saga";

//Calendar
import calendarSaga from "./calendar/saga";

//Chat
import chatSaga from "./chat/saga";

//Invoices
import invoiceSaga from "./invoices/saga";

//Contact
import contactsSaga from "./contacts/saga";

//Login
import authSaga from "./auth/login/saga";

//Register
import registerSaga from "./auth/register/saga";

//User Profile
import ProfileSaga from "./auth/profile/saga";

// Forget Password
import forgetPasswordSaga from "./auth/forgetpwd/saga"

//states saga
import stateSaga, {fetchStates} from "./states/saga";

//customer
import customerSaga, { fetchCustomers } from "./customers/saga";

//product
import productSaga,{fetchProducts} from "./products/saga";

//company
import companySaga,{fetchCompanies} from "./companies/saga"; 

//unit
import unitSaga,{fetchUnits} from "./units/saga";

//tax
import taxSaga,{fetchTaxes} from "./taxes/saga";

//countries
import countrySaga, { fetchCountries } from "./countries/saga";

//currencies
import  currencySaga, { fetchCurrencies } from "./currencies/saga";

//currencies
import  permissionSaga, { fetchPermissions } from "./permissions/saga";

// expense items
import  expItemSaga, { fetchExpItems } from "./exp_items/saga";

import { isValidToken } from "src/helpers/api_helper";
import { fetchPaymentMethods } from "./payment_methods/saga";
import { fetchbankorcash } from "./bankorcash/saga";
import { fetchtdshead } from "./tdshead/saga";
import { fetchHsncode } from "./hsncode/saga";
import { fetchSacCode } from "./saccode/saga";

export function* initalData() {
  yield all([   
    fork(fetchPermissions), 
    fork(fetchCustomers), 
    fork(fetchProducts),
    fork(fetchCompanies),
    fork(fetchStates),
    fork(fetchCountries),       
    fork(fetchUnits),
    fork(fetchTaxes),
    fork(fetchCurrencies),
    fork(fetchExpItems),
    fork(fetchPaymentMethods),
    fork(fetchbankorcash),
    fork(fetchtdshead),
    fork(fetchHsncode),
    fork(fetchSacCode)
  ])
}

export default function* rootSaga() {
  let call = [
    //public
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(invoiceSaga),
    fork(contactsSaga),
    fork(authSaga),
    fork(registerSaga),
    fork(ProfileSaga),
    fork(forgetPasswordSaga),
    fork(stateSaga),
    fork(customerSaga),
    fork(productSaga),
    fork(companySaga),
    fork(unitSaga),
    fork(taxSaga),
    fork(countrySaga),
    fork(currencySaga),
    fork(permissionSaga),
    fork(expItemSaga)   
  ]; 
  if (isValidToken()) {    
    call.push(fork(initalData))
  }
  yield all(call)  
}
