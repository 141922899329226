import { call, put, takeEvery } from "redux-saga/effects"

import {
  getSacCodeSuccess,
  getSacCodeFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { SacCodeTypes } from "./actionTypes"

export const getSacCode = () => get(process.env.REACT_APP_API_URL + '/api/all_sac_code')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchSacCode() {
  try {
    const response : apiRes = yield call(getSacCode);  
    if (response.success) {
      yield put(getSacCodeSuccess(response))
    } 
  } catch (error) {
    yield put(getSacCodeFail(error))
  }
}


function* saccodeSaga() {  
  yield takeEvery(SacCodeTypes.GET_SACCODE, fetchSacCode);
}

export {fetchSacCode}
export default saccodeSaga
