import {HsnCodeTypes} from "./actionTypes"

export const getHsnCode = () => ({
  type: HsnCodeTypes.GET_HSNCODE,
})

export const getHsnCodeSuccess = (taxes : any) => ({
  type: HsnCodeTypes.GET_HSNCODE_SUCCESS,
  payload: taxes,
})

export const getHsnCodeFail = (error : any) => ({
  type: HsnCodeTypes.GET_HSNCODE_FAIL,
  payload: error,
})



